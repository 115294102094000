@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Arcade Gamer";
    src: url("../public/fonts/ArcadeGamer-Regular.otf");
}

html {
    background-color: #ffd300;
    color: #050A30;
    font-family: "Arcade Gamer", sans-serif;
}

.min-safe-h-screen {
    /* equivalent to 100dvh in major browsers */
    min-height: calc(
            100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );
}

@supports (-webkit-touch-callout: none) {
    .min-safe-h-screen {
        /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
        min-height: -webkit-fill-available;
    }
}
